$(document).ready(function() {
    //Data Table
    $("#basic-datatable").each(function (idx, obj) {
        $(obj).DataTable({
            "info": false,
            "lengthChange": false,
            "scrollCollapse": false,
            "paging": false,
            "language": {
                "searchPlaceholder": "Search records",
                "paginate": {
                    "previous": "<i class='mdi mdi-chevron-left'>",
                    "next": "<i class='mdi mdi-chevron-right'>"
                }
            },
            "drawCallback": function () {
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
            }
        });
    });

    $("#basic-datatable_filter").each(function (idx, obj) {
        $(obj).find("input").appendTo('.search-box');
        $(obj).remove();
    });

    $("#datatable-buttons").each(function (idx, obj) {
        $(obj).DataTable({
            lengthChange: false,
            serverSide: true,
            ajax: '/users/list/',
            "columnDefs": [
                {"targets": "unorderable", "orderable": false}
            ],
            buttons: [
                {
                    extend: 'csvHtml5',
                    text: 'Download CSV'
                }
            ],
            "language": {
                "searchPlaceholder": "Search records",
                "paginate": {
                    "previous": "<i class='mdi mdi-chevron-left'>",
                    "next": "<i class='mdi mdi-chevron-right'>"
                }
            },
            "drawCallback": function () {
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
            }
        });

        $(obj).DataTable().buttons().container().appendTo('.page-title-right');
    });

    $("#datatable-buttons_filter").each(function (idx, obj) {
        $(obj).find("input").appendTo('.search-box');
        $(obj).remove();
    });
});

$(document).ready(function() {
    $("#file-dropzone").each(function (idx, obj) {
        $(obj).dropzone({
            maxFiles: 1,
            maxFilesize: 600,
            acceptedFiles: ".xls",
            autoProcessQueue: false,
            previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,
            previewsContainer: "#file-previews",

            init: function () {
                var submitButton = $("#submit-upload");
                dropzone = this;

                submitButton.click( function () {
                    if (dropzone.getUploadingFiles().length > 0) {
                        dropzone.removeAllFiles(true);
                        $(this).text("Begin upload");
                    } else {
                        dropzone.processQueue();
                        $(this).text("Cancel upload");
                    }
                    $(this).toggleClass("btn-primary btn-secondary");
                });

                this.on("success", function(file, responseText) {
                    if (responseText['status'] == 'success') {
                        window.location.href = responseText['next'];
                    } else if (responseText['status'] == 'error') {
                        submitButton.text(responseText['message']);
                        submitButton.prop("disabled", true);
                    }
                });

                this.on("addedfile", function () {
                   $(obj).hide();
                    submitButton.text("Begin upload");
                    submitButton.prop("disabled", false);
                });

                this.on("removedfile", function () {
                    $(obj).show();
                    submitButton.text("Begin upload");
                    submitButton.prop("disabled", true);
                });

                this.on("maxfilesexceeded", function(file) {
                    this.removeAllFiles(true);
                    this.addFile(file);
                });
            }
        });
    });
});

$(document).ready( function() {
    $(document).on("click", ".data-modal", function () {
        event.preventDefault();
        var action = $(this).data('action');
        var title = $(this).data('title');
        var text = $(this).data('text');
        var modal = $($(this).data('target'));
        modal.find('.modal-title').text(title);
        modal.find('.modal-text').text(text);
        modal.find('form').attr('action', action);
    });

    $(document).on("click", ".xhr-modal", function () {
        event.preventDefault();
        var modal = $($(this).data('target'));
        var action = $(this).data('action');
        modal.find('.modal-title').text($(this).data('title'));
        $.get(action, function (response) {
            var form = $(response).filter('form');
            modal.find('.modal-body').html(form);
            $('.dateinput').datepicker({
                format: "dd.mm.yyyy"
            });
        });
    });

    $(document).on("submit", ".xhr-form", function() {
        event.preventDefault();
        var form = $(this);
        var action = form.attr('action');
        postItemForm(action, form, true);
    });

    $('.xhr-switch').on('change', function() {
        var form = $(this).closest("form");
        var action = form.attr("action");
        postItemForm(action, form, false)
    });

    function postItemForm(action, form, reload) {
        var formData = form.serialize();
        $.post(action, formData, function(response) {
        }).done(function () {
            if(reload) {
                location.reload();
            }
        });
    }
});
